/** 接口错误状态码 */
export enum CopyTradingApiErrorCode {
  /** 跟单设置 - 带单项目已关闭，跟单创建失败 */
  projectNotExists = 10160008,
  /** 跟单设置 - 跟单人数已满，跟单创建失败 */
  projectFollowOverLimit = 10160009,
  /** 当前用户未跟随此项目 */
  projectIsNotFollow = 10160013,
  /** 不能跟单自己带单的项目 */
  notFollowMySelfProject = 10160014,
}

/**
 * 用户带单/跟单开通状态
 */
export enum CopyTradingUserTakerStatusEnum {
  /** 已开通 */
  open = 'open',
  /** 未开通 */
  notOpen = 'notOpen',
  /** 审核中 */
  auditing = 'auditing',
}

/**
 * 是否需要 KYC 认证
 */
export enum CopyTradingNeedKycEnum {
  /** 不需要 */
  no = 'no',
  /** 需要 */
  yes = 'yes',
}

/**
 * 创建项目类型
 */
export enum CopyTradingCreateProjectTypeEnum {
  /** 新建 */
  add = 'add',
  /** 编辑 */
  edit = 'edit',
}

export const PAGE_SIZE = 10 // 分页条数
export const PAGE_NUM = 1 // 分页大小

// 分页对象类型
export type PaginationType = {
  sizeCanChange: boolean // 是否分页
  showTotal: boolean // 是否显示总数
  total: number // 总条数
  pageSize: number // 每页条数
  current: number // 当前页
  pageSizeChangeResetCurrent: boolean // 页面刷新是否显示第一页
}

/**
 * 个人带单分润接口枚举
 */
export enum TakerProfitEnum {
  /** 已分润 */
  yes = 'yes',
  /** 未分润 */
  no = 'no',
}
