import { t } from '@lingui/macro'
/** 交易周期 */
export enum TradingCycleEnum {
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}
export function getTradingCycleLabelEnum(value: TradingCycleEnum) {
  return {
    [TradingCycleEnum.Long]: t`constants_copy_trading_8zjbct3qvk`,
    [TradingCycleEnum.Medium]: t`constants_copy_trading_0khi8wtquh`,
    [TradingCycleEnum.Short]: t`constants_copy_trading_hqeih78rqu`,
  }[value]
}

/** 交易习惯 */
export enum TradingHabitEnum {
  Stable = 'stable',
  Aggressive = 'aggressive',
  Agile = 'agile',
}
export function getTradingHabitLabelEnum(value: TradingHabitEnum) {
  return {
    [TradingHabitEnum.Stable]: t`constants_copy_trading_xrdkpcduxk`,
    [TradingHabitEnum.Aggressive]: t`constants_copy_trading_imcaiin9vh`,
    [TradingHabitEnum.Agile]: t`constants_copy_trading_wskxpxtpfe`,
  }[value]
}

/** 技术手段 */
export enum TradingToolEnum {
  Indicator = 'indicator',
  Trend = 'trend',
  News = 'news',
}
export function getTradingToolLabelEnum(value: TradingToolEnum) {
  return {
    [TradingToolEnum.Indicator]: t`constants_copy_trading_cqwqj5n1f8`,
    [TradingToolEnum.Trend]: t`constants_copy_trading_bwgpka0uco`,
    [TradingToolEnum.News]: t`constants_copy_trading_xmdyiw8nd5`,
  }[value]
}

/** 分润规则 */
export enum TradingDivideRuleEnum {
  Periodic = 'timing', // 周期结算
  Cumulative = 'accumulative', // 累计结算
}
export function getDivideRuleLabelEnum(value: TradingDivideRuleEnum) {
  return {
    [TradingDivideRuleEnum.Periodic]: t`constants_copy_trading__hawxq0dd8`,
    [TradingDivideRuleEnum.Cumulative]: t`constants_copy_trading_luntbbl4tt`,
  }[value]
}

/** 当前/历史项目 */
export enum ProjectStatusType {
  CURRENT = 'current',
  HISTORY = 'history',
}

/** 当前/历史项目 排序规则 */
export enum ProjectSortRuleType {
  COMPREHENSIVE = 'complex' /** 综合排序 */,
  PROFIT = 'followEarnings' /** 跟随者收益 */,
  SIZE = 'followValue' /** 带单规模 */,
  FOLLOWER = 'followCnt' /** 跟单人数 */,
}

/** 当前/历史项目 - 默认筛选条件 */
export const defaultProjectFilters = {
  /** 跟随者收益 起始值 */
  followEarningsFrom: 0,
  /** 带单规模 起始值 */
  projectFollowValueFrom: 0,
  /** 标签 */
  projectTags: '',
  tagsCycle: [],
  tagsHabit: [],
  tagsTool: [],
  /** 是否过滤满员  */
  filterFull: false,
}

/**
 * 跟单广场 - 数据字典
 */
export enum ProjectTagsEnum {
  /** 交易周期 */
  followCycleType = 'follow_project_cycle',
  /** 交易习惯 */
  followHabitType = 'follow_project_habit',
  /** 技术手段 */
  followTechnologyType = 'follow_project_technology',
  /** 用户标签 */
  followUserTag = 'follow_user_tag',
}

/** 个人主页 tabs */
export enum MyUserCenterTabsEnum {
  /** 动态 */
  dynamic = 'dynamic',
  /** 带单 */
  takersOrders = 'takers_orders',
  /** 跟单 */
  followOrders = 'follow_orders',
}

/** 个人主页 */
export enum ModalTypeEnum {
  /** 关注 */
  concern = 0,
  /** 订阅 */
  subscribe = 1,
}

/** 带单 tabs */
export enum TakeOrdersTabsEnum {
  /** 带单数据 */
  OrderData = 'order_data',
  /** 当前项目 */
  currentProject = 'current_project',
  /** 历史项目 */
  history = 'history',
  /** 我的跟随者 */
  myFollows = 'my_follows',
  /** 带单分润 */
  profit = 'profit',
}

/** 我的交易员 tabs */
export enum MyTraderTabsEnum {
  /** 正在进行 */
  begin = 0,
  /** 已结束 */
  end = 1,
}
/** 跟单 tabs */
export enum FollowOrdersTabsEnum {
  /** 当前跟单 */
  hurrentOrder = 'hurrent_order',
  /** 历史跟单 */
  history = 'history',
  /** 我的交易员 */
  follows = 'follows',
}

/** 带单数据右侧 tabs */
export enum LedDataTabsEnum {
  /** 收益率 */
  rate = 0,
  /** 收益 */
  account = 1,
}

/** 带单数据时间范围 */
export enum TimeDataEnum {
  /** 近一周 */
  week = 0,
  /** 近 14 天 */
  halfMonth = 1,
  /** 近 30 天 */
  month = 2,
  /** 近一年 */
  all = 3,
}

/** 当前项目 tabs */
export enum CurrentProjectEnum {
  /** 当前仓位 */
  current = 0,
  /** 历史仓位 */
  history = 1,
}

/** 他人主页 tabs */
export enum OthersUserCenterTabsEnum {
  /** 动态 */
  c2c = 'c2c',
  /** 带单 */
  takersOrders = 'takers_orders',
  /** 跟单 */
  followOrders = 'follow_orders',
}

/** 他人主页带单 tabs */
export enum OthersTakeOrdersTabsEnum {
  /** 带单数据 */
  OrderData = 'order_data',
  /** 当前项目 */
  currentProject = 'current_project',
  /** 历史项目 */
  history = 'history',
  /** 跟随者 */
  follows = 'follows',
}

/** 他人主页跟单 tabs */
export enum FollowHomepageTabs {
  /** 当前跟随 */
  following = 0,
  /** 历史跟随 */
  history = 1,
  /** 交易员 */
  traders = 2,
}

/** 创建项目 - 跟单总开仓保证金上限 */
export enum MaxFollowMarginEnum {
  /** 无上限 */
  noLimit = 'noLimit',
  /** 上限金额 */
  limit = 'limit',
}

export const getMaxFollowMarginName = (type: MaxFollowMarginEnum) => {
  return {
    [MaxFollowMarginEnum.noLimit]: t`constants_copy_trading_ijqwyjhupa`,
    [MaxFollowMarginEnum.limit]: t`constants_copy_trading_cgwf2_mwzv`,
  }[type]
}

/** 创建项目 - 跟单最大杠杆倍数 */
export enum MaxFollowLeverageEnum {
  /** 无限制 */
  noLimit = 'none',
  /** 不超过交易员 */
  dynamic = 'dynamic',
}

export const getMaxFollowLeverageName = (type: MaxFollowLeverageEnum) => {
  return {
    [MaxFollowLeverageEnum.noLimit]: t`constants_copy_trading_q55odjfyyr`,
    [MaxFollowLeverageEnum.dynamic]: t`constants_copy_trading_mkfpvq2fng`,
  }[type]
}

export const getTimeName = (type: TimeDataEnum) => {
  return {
    [TimeDataEnum.week]: t`constants_copy_trading_a2zibbu6nt`,
    [TimeDataEnum.halfMonth]: t`constants_copy_trading_zhfkaeabcu`,
    [TimeDataEnum.month]: t`constants_copy_trading_mqrrhyaxle`,
    [TimeDataEnum.all]: t`common.all`,
  }[type]
}

// 时间选择范围
export const TimeList = [
  {
    title: t`constants_copy_trading_a2zibbu6nt`,
    id: TimeDataEnum.week,
    value: 7,
  },
  {
    title: t`constants_copy_trading_zhfkaeabcu`,
    id: TimeDataEnum.halfMonth,
    value: 14,
  },
  {
    title: t`constants_copy_trading_mqrrhyaxle`,
    id: TimeDataEnum.month,
    value: 30,
  },
  {
    title: t`common.all`,
    id: TimeDataEnum.all,
    value: 0,
  },
]

/**
 * 当前项目 - 跟单状态
 */
export enum ProjectFollowStatusEnum {
  /** 申请中 */
  applying = 'applying',
  /** 正常 */
  following = 'following',
  /** 拒绝申请 */
  refuse = 'refuse',
  /** 踢出 */
  out = 'out',
}

/**
 * 跟单按钮 - 页面类型
 */
export enum FollowBtnPageTypeEnum {
  /** 跟单广场 */
  square = 'square',
  /** 项目 */
  project = 'project',
}
