import { CopyTradingCreateProjectTypeEnum } from '@/constants/copy-trading/common'
import {
  MyUserCenterTabsEnum,
  OthersUserCenterTabsEnum,
  TakeOrdersTabsEnum,
  OthersTakeOrdersTabsEnum,
} from '@/constants/copy-trading'

/**
 * 跟单设置
 * @param projectId 项目 id
 * @param isMark 是否从广场跳转
 * @returns
 */
export function getCopyTradingSettingsRoutePath(projectId?: string, isMark = true) {
  let url = `/copy-trading/settings`
  if (projectId) url += `?id=${projectId}`
  if (isMark) url += `&isMark=${isMark}`
  return url
}

/** 跟单广场 */
export function getCopyTradingRoutePath() {
  return '/copy-trading'
}

/** 跟单广场 - 交易员申请 */
export function getCopyTradingBeLeaderRoutePath(submitted?: string) {
  let url = '/copy-trading/be-leader'
  if (submitted) url += `?submitted=${submitted}`
  return url
}

/** 跟单广场 - 编辑带单项目 */
export function getCopyTradingEditProjectPath() {
  return '/copy-trading/edit-project/@id'
}

/**
 * 跟单广场 - 创建带单项目
 * @param type  CopyTradingCreateProjectTypeEnum add=新增，edit=编辑
 */
export function getCopyTradingCreateProjectRoutePath(type = CopyTradingCreateProjectTypeEnum.add) {
  return `/copy-trading/create-project?type=${type}`
}

/**
 * 个人主页
 * @param type
 * @param tab
 * @returns url
 */
export function getCopyTradingMyUserCenterRoutePath({
  type = MyUserCenterTabsEnum.takersOrders,
  tab = '',
  proJectId = '',
}) {
  let url = '/copy-trading/user-center/my'

  if (type) {
    url = `${url}?type=${type}${tab ? `&tab=${tab}` : ''}`
  }
  if (proJectId) {
    url = `${url}${proJectId ? `&proJectId=${proJectId}` : ''}`
  }
  return url
}

/**
 * 他人主页
 * @param uid 用户 uid 查看自己不传
 * @param type
 * @returns url
 */
export function getCopyTradingOthersUserCenterRoutePath({
  uid = '',
  type = OthersUserCenterTabsEnum.followOrders,
  tab = '',
  proJectId = '',
}) {
  let url = '/copy-trading/user-center/others'

  if (uid) {
    url = `${url}?uid=${uid}`
  }

  if (type) {
    url = `${url}&type=${type}`
  }
  if (tab) {
    url = `${url}${tab ? `&tab=${tab}` : ''}`
  }
  if (proJectId) {
    url = `${url}${proJectId ? `&proJectId=${proJectId}` : ''}`
  }
  return url
}

/**
 * 带单订单
 */

export function getOrderWithOrder() {
  let url = 'copy-trading/order-with'
}

/**
 * 我的订阅
 * @returns url
 * */
export function getCopyTradingMySubscriptionsRoutePath() {
  let url = '/copy-trading/my-subscriptions'
  return url
}
